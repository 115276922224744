<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
    <v-row dense>
      <v-col>
        <v-text-field dense label="Equipamento" v-model="currentItem.nome" outlined :rules="requiredField" />
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          dense
          label="Tipo de Equipamento"
          v-model="currentItem.tipo_equipamento"
          :items="tipo"
          item-text="nome"
          item-value="id"
          outlined
          :rules="requiredField"
        />
      </v-col>
      <v-col cols="2">
        <vuetify-money dense label="Custo estimado" v-model="currentItem.custo" outlined :rules="requiredField" />
      </v-col>
      <v-col cols="3">
        <vuetify-money dense label="Valor Equipamento Reposição" v-model="currentItem.valor_cobranca" outlined :rules="requiredField" />
      </v-col>
    </v-row>
    <v-row dense>
      <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
        <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
        <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
      </v-btn-toggle>
    </v-row>
    <v-row class="justify-end mt-0" dense>
      <v-col class="text-end">
        <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import api from '../../../../../http';
import UtilsFunc from '../../../../../service/utilsFunc';
import ConfirmButton from '../../../../ConfirmButton.vue';

export default {
  name: "BaseFormProcedure",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      requiredField: [e => (e ? true : 'Obrigatório')],
      tipo: []
    })
  },
  components: { ConfirmButton },
  methods: {
    localOnSubmit() {
      const validation = this.$refs.formRef.validate()
      if (!validation)
        return
      const fields = {
        ...this.currentItem,
        // nome: this.currentItem.nome,
        // custo: this.currentItem.custo,
        // valor_cobranca: this.currentItem.valor_cobranca,
        // tipo_equipamento: this.currentItem.tipo_equipamento,
        // ativo: this.currentItem.ativo
      }
      console.log('Enviado => ', fields)
      this.onSubmit(fields)
    },
    async getTipoEquipamento() {
      try {
        const { data } = await api.get("/equipamentos/tipoequipamentos/");
        this.tipo = data.filter(item => item.ativo);
        // console.log(this.tipo)
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      }
    },
  },
  mounted() {
    this.getTipoEquipamento();
    this.currentItem.ativo = Number(this.currentItem.ativo)
  }
}
</script>
