<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
    <v-row dense>
      <v-col>
        <v-text-field
          dense
          label="Nome do Kit"
          v-model="currentItem.nome"
          outlined
          :rules="requiredField"
        />
      </v-col>
    </v-row>

    <!-- Status do Kit -->
     <v-row dense>
      <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
        <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
        <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
      </v-btn-toggle>
    </v-row>

    <!-- Botão de Salvar -->
    <v-row class="justify-end mt-0" dense>
      <v-col class="text-end">
        <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import UtilsFunc from "../../../../../service/utilsFunc";
import ConfirmButton from "../../../../ConfirmButton.vue";


export default {
  name: "CriacaoNomeKit",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean,
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      requiredField: [(e) => (e ? true : "Obrigatório")],
      equipamento: [], // Equipamentos disponíveis carregados da API
      selectedEquipamentos: [], // Equipamentos selecionados no combobox
    });
  },
  components: { ConfirmButton },
  methods: {
    localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) return;

      // Cria o objeto fields com o nome e o status do kit
      const fields = {
        nome: this.currentItem.nome,
        equipamento: [],
        ativo: this.currentItem.ativo, // Enviar apenas o nome e status
      };

      // Envia os dados ao backend
      this.onSubmit(fields);
    },
  },

};
</script>
