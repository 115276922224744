<template>
  <v-form @submit.prevent="localOnSubmit" ref="formRef">
    <v-row dense>
      <v-col>
        <v-text-field
          dense
          label="Nome do Kit"
          v-model="currentItem.nome"
          outlined
          :rules="requiredField"
        />
      </v-col>
    </v-row>

    <!-- Equipamentos no Kit -->
    <v-card outlined class="mb-4">
      <v-card-title>
        <v-icon left>mdi-wrench</v-icon>
        Equipamentos Selecionados no Kit
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-list dense>
          <v-list-item-group>
            <v-list-item
              v-for="equipamento in currentItem.equipamento"
              :key="equipamento?.id"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ equipamento?.nome || "Equipamento indefinido" }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="removeEquipamento(equipamento)">
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>

    <!-- Combobox de Adição de Equipamentos -->
    <v-card outlined class="mb-4">
      <v-card-title>
        <v-icon left>mdi-plus-circle-outline</v-icon>
        Adicionar Equipamentos ao Kit
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-combobox
          v-model="selectedEquipamentos"
          :items="equipamento"
          item-text="nome"
          item-value="id"
          label="Selecione os equipamentos"
          multiple
          outlined
          chips
          dense
        />
      </v-card-text>
    </v-card>

    <!-- Status do Kit -->
     <v-row dense>
      <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
        <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
        <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
      </v-btn-toggle>
    </v-row>

    <!-- Botão de Salvar -->
    <v-row class="justify-end mt-0" dense>
      <v-col class="text-end">
        <ConfirmButton :loading="loading" color="success">Salvar</ConfirmButton>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import UtilsFunc from "../../../../../service/utilsFunc";
import ConfirmButton from "../../../../ConfirmButton.vue";
import api from "../../../../../http";

export default {
  name: "BaseFormKitEquipamento",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean,
  },
  data() {
    return UtilsFunc.withCRUDUtils({
      requiredField: [(e) => (e ? true : "Obrigatório")],
      equipamento: [], // Equipamentos disponíveis carregados da API
      selectedEquipamentos: [], // Equipamentos selecionados no combobox
    });
  },
  components: { ConfirmButton },
  methods: {
    async getEquipamento() {
      const LOADING_NAME = "get:equipamento";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("/equipamentos/nomeequipamentos/");
        this.equipamento = data.filter((item) => item.ativo); // Carregar apenas os equipamentos ativos
        this.mapCurrentItemEquipamentos(); // Mapeia IDs para objetos completos
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    // Mapeia os IDs de currentItem.equipamento para os objetos completos
    mapCurrentItemEquipamentos() {
      if (this.currentItem.equipamento && Array.isArray(this.currentItem.equipamento)) {
        this.currentItem.equipamento = this.currentItem.equipamento
          .map((id) => {
            const equipamentoObj = this.equipamento.find((equip) => equip.id === id);
            return equipamentoObj || { id, nome: "Equipamento indefinido" };
          })
          // Ordena por nome do equipamento
          .sort((a, b) => a.nome.localeCompare(b.nome));
      }
    },
    removeEquipamento(equipamento) {
      this.currentItem.equipamento = this.currentItem.equipamento.filter(
        (e) => e?.id !== equipamento?.id
      );
    },
    localOnSubmit() {
      const validation = this.$refs.formRef.validate();
      if (!validation) return;

      // Combine os equipamentos existentes com os selecionados no combobox
      const combinedEquipamentos = [
        ...this.currentItem.equipamento.map((e) => e.id),  // IDs dos equipamentos já existentes
        ...this.selectedEquipamentos.map((e) => e.id), // IDs dos equipamentos recém-selecionados
      ];

      // Remover duplicatas
      const uniqueEquipamentos = [...new Set(combinedEquipamentos)];

      // Cria o objeto fields com os IDs únicos dos equipamentos
      const fields = {
        ...this.currentItem,
        equipamento: uniqueEquipamentos,  // Envia IDs únicos no payload
      };
      // console.log('Enviando --> ', fields)
      // Envia os dados ao backend
      this.onSubmit(fields);
    },
  },
  mounted() {
    this.getEquipamento(); // Carrega os equipamentos disponíveis
    this.currentItem.ativo = Number(this.currentItem.ativo)
  },
};
</script>
